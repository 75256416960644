import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { useEffect, useState } from "react";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/outline";

interface PaginationState {
  data: any[];
  page: number;
  perPage: number;
  totalItems: number;
  totalPages: number;
  isLoading: boolean;
}
export default function NavigationComponent({
  selectPagingData,
  handleChangePage,
}: {
  selectPagingData: (state: RootState) => PaginationState;
  handleChangePage: (page: number) => void;
}) {
  const pagingData = useSelector(selectPagingData);
  const { page, perPage, totalItems, totalPages, isLoading } = pagingData;
  const [currentPage, setCurrentPage] = useState(Number(page));
  const [startItem, setStartItem] = useState((currentPage - 1) * perPage + 1);
  const [endItem, setEndItem] = useState(
    Math.min(currentPage * perPage, totalItems),
  );
  const [pageNumbers, setPageNumbers] = useState<number[]>([]);

  useEffect(() => {
    setStartItem((currentPage - 1) * perPage + 1);
    setEndItem(Math.min(currentPage * perPage, totalItems));

    const newStartPage = Math.max(
      1,
      currentPage -
        2 -
        (currentPage > totalPages - 2 ? 2 - (totalPages - currentPage) : 0),
    );
    const newEndPage = Math.min(
      totalPages,
      currentPage + 2 + (currentPage <= 2 ? 3 - currentPage : 0),
    );

    const newPageNumbers: number[] = [];
    for (let i = newStartPage; i <= newEndPage; i++) {
      newPageNumbers.push(i);
    }

    setPageNumbers(newPageNumbers);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagingData]);

  const handleFirstPage = () => {
    setCurrentPage(1);
    handleChangePage(1);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      handleChangePage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      handleChangePage(currentPage + 1);
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    handleChangePage(totalPages);
  };

  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    handleChangePage(pageNumber);
  };

  return (
    <div className="relative items-center justify-between overflow-hidden bg-zinc-900/10 px-1 py-3 marker:flex sm:px-1">
      <div className={isLoading ? "animate-pulse" : ""}>
        <div className="flex flex-1 justify-between sm:hidden">
          <button
            onClick={handlePrevious}
            className="font-sm relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1 text-sm text-gray-700 hover:bg-gray-50"
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            className="font-sm relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1 text-sm text-gray-700 hover:bg-gray-50"
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-white">
              Showing <span className="font-medium">{startItem}</span> to{" "}
              <span className="font-medium">{endItem}</span> of{" "}
              <span className="font-medium">{totalItems}</span> results
            </p>
          </div>
          <div>
            <nav
              aria-label="Pagination"
              className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            >
              <button
                onClick={handleFirstPage}
                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                disabled={currentPage === 1}
              >
                <span className="sr-only">Previous</span>
                <ChevronDoubleLeftIcon aria-hidden="true" className="h-5 w-5" />
              </button>
              <button
                onClick={handlePrevious}
                className="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                disabled={currentPage === 1}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
              </button>
              {pageNumbers.map((number) => (
                <button
                  key={number}
                  onClick={() => handlePageClick(number)}
                  className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                    number === currentPage
                      ? "bg-indigo-600 text-white ring-1 ring-inset ring-indigo-600"
                      : "text-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-black"
                  } focus:z-20 focus:outline-offset-0`}
                >
                  {number}
                </button>
              ))}
              <button
                onClick={handleNext}
                className="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                disabled={currentPage === totalPages}
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon aria-hidden="true" className="h-5 w-5" />
              </button>
              <button
                onClick={handleLastPage}
                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                disabled={currentPage === totalPages}
              >
                <span className="sr-only">Next</span>
                <ChevronDoubleRightIcon
                  aria-hidden="true"
                  className="h-5 w-5"
                />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
