import { BoltIcon } from "@heroicons/react/24/outline";
import { ChartBarIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import {
  generateDexscreenerUrl,
  generatePhotonUrl,
} from "../../../utils/utils";

export const LinkAndBotComponent = ({ token }: { token: string }) => {
  return (
    <div className="flex flex-col xl:flex-row xl:items-center xl:gap-4 text-blue-600 underline text-sm">
      <div className="flex sm:flex-row gap-1">
        <ChartBarIcon className="w-4 h-5 text-green-500"></ChartBarIcon>
        <Link to={`${generatePhotonUrl(token)}`} target="_blank">
          Photon
        </Link>
        <span>|</span>
        <Link to={`${generateDexscreenerUrl(token)}`} target="_blank">
          Dexscreener
        </Link>
      </div>
      <div className="flex sm:flex-row gap-1">
        <BoltIcon className="w-4 h-5 text-green-500"></BoltIcon>
        <Link
          to={`https://t.me/bonkbot_bot?start=ref_wd927_${token}`}
          target="_blank"
        >
          Bonk Bot
        </Link>
        <span>|</span>
        <Link
          to={`https://t.me/SolanaTradingBot?start=oamCfYT3Q-${token}`}
          target="_blank"
        >
          SolanaTradingBot
        </Link>
        <span>|</span>
        <Link
          to={`https://t.me/hector_trojanbot?start=r-khanh000-${token}`}
          target="_blank"
        >
          UniBot
        </Link>
      </div>
    </div>
  );
};
