import { TelegramCallResponse } from "../../types/Token.types";
import { LinkAndBotComponent } from "../components/LinkAndBotComponent";
import { fetchSimilarHolderListRequest, updateChannelCall } from "../newTokenSlice";
import { useDispatch } from "react-redux";
import { MutableRefObject, useRef, useState } from "react";
import { HolderType } from "../../types/Holder.types";
import { UnknownAction } from "@reduxjs/toolkit";
import { SimilarHolderComponent } from "../components/SimilarHoldersComponent";
import { convertTimestampToDateString, formatNumber } from "../../../utils/utils";
import { TokenListItem } from "../token/TokenListItem";
import ChannelCallDetail from "./ChannelCallDetail";
import { OpenSimilarHolderButtonComponent } from "../components/OpenSimilarHolderButtonComponent";
import { TokenDetailButtonComponent } from "../components/TokenDetailButtonComponent";

interface ChannelCallListProps {
  data?: TelegramCallResponse[];
  page: number;
  perPage: number;
  totalItems: number;
  totalPages: number;
  isLoading: boolean;
}
export default function ChannelCallList(props: ChannelCallListProps) {
  const { data } = props;
  const [openSimilarHolderDialog, setOpenSimilarHolderDialog] = useState(false);
  type SortType = {
    [key: string]: { sortType: number; sortOrder: number } | null;
  };

  const sort: MutableRefObject<SortType> = useRef<SortType>({});

  const dispatch = useDispatch();

  function doOpenSimilarHolderDialog(token: string): void {
    console.log("doOpenSimilarHolderDialog", token);
    setOpenSimilarHolderDialog(true);
    dispatch(
      fetchSimilarHolderListRequest({
        token,
        type: HolderType.Burn,
      }) as unknown as UnknownAction,
    );
  }
  const sortPeers = (peers: TelegramCallResponse["peers"], sortType: number, sortOrder: number) => {
    const sortedPeers = [...peers];
    if (sortType === 1) {
      // Sort by subscribers
      sortedPeers.sort((a, b) => (b.subscribers - a.subscribers) * sortOrder);
    } else if (sortType === 2) {
      // Sort by dateTime
      sortedPeers.sort((a, b) => (b.dataTime - a.dataTime) * sortOrder);
    }
    return sortedPeers;
  };

  const updateSortPeers = (call: TelegramCallResponse, sortType: number) => {
    const sortOrder = sort.current[call._id]?.sortOrder === 1 ? -1 : 1;
    const sortedPeers = sortPeers(call.peers, sortType, sortOrder);
    sort.current[call._id] = { sortOrder, sortType };
    dispatch(updateChannelCall({ ...call, peers: sortedPeers }));
  }

  return (
    <div className="md:max-h-[75vh] overflow-y-scroll">
      {data && data.length > 0 && data.map((call) => {
        return (
          <TokenListItem
            token={call.token}
            date={convertTimestampToDateString(call.lastCall)}
            key={call._id}
          >
            <div className="flex flex-col px-4 text-sm text-white">
              {call.peers && <ChannelCallDetail {...call}></ChannelCallDetail>}
            </div>
            <div className="flex flex-col gap-1 py-2 pl-4 pr-4 xl:flex-row">
              <LinkAndBotComponent token={call.token.token} />
              <div className="mt-2 flex flex-auto justify-end gap-2 xl:mt-0">
                <OpenSimilarHolderButtonComponent
                  onClick={() => doOpenSimilarHolderDialog(call.token.token)}
                />
                <TokenDetailButtonComponent token={call.token.token} />
              </div>
            </div>
          </TokenListItem>
        );
      })}
      {openSimilarHolderDialog && (
        <SimilarHolderComponent
          onClose={() => {
            setOpenSimilarHolderDialog(false);
          }}
        />
      )}
    </div>
  );

}
