import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchTokenDetailRequest,
    selectTokenDetailState,
} from "../features/token-list/newTokenSlice";
import { UnknownAction } from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";
import LoadingPlaceholderComponent from "../features/token-list/components/LoadingPlaceholderComponent";
import ErrorAndReloadButtonComponent from "../features/token-list/components/ErrorAndReloadButtonComponent";
import TokenDetail from "../features/token-list/token/TokenDetail";

export default function TokenDetailPage() {
    const dispatch = useDispatch();
    const state = useSelector(selectTokenDetailState);

    const { token } = useParams();
    useEffect(() => {
        if (token) {
            dispatch(
                fetchTokenDetailRequest({ token }) as unknown as UnknownAction,
            );
        }
    }, [token]);

    useEffect(() => {
        console.log(state);
    }, [state])

    return (
        <>
            {state.isLoading ? (
                <LoadingPlaceholderComponent />
            ) : state.error ? (
                <ErrorAndReloadButtonComponent
                    onReload={() =>
                        token && dispatch(
                            fetchTokenDetailRequest({ token }) as unknown as UnknownAction,
                        )
                    }
                />
            ) : (
                <>
                    <TokenDetail {...state} />
                </>
            )}
        </>
    );
}
