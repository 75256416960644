import { Dispatch } from "@reduxjs/toolkit";
import { Socket } from "./Socket";
import { SocketOptions, WebSocketEvent } from "./Socket.types";

export class SocketService {
  private socket: Socket;
  private reconnectInterval: NodeJS.Timer | undefined;
  private dispatch: Dispatch;
  private options: SocketOptions;
  private socketUrl: string;
  constructor(socketUrl: string, dispatch: Dispatch, options: SocketOptions) {
    console.log("new SocketService");
    this.socketUrl = socketUrl;
    this.dispatch = dispatch;
    this.options = options;
    this.socket = new Socket();
  }

  connect() {
    if (this.socketUrl) {
      this.socket.connect(this.socketUrl);
      this.setListener();
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
    this.clearReconnectInterval();
    this.removeListeners();
  }

  reconnect() {
    this.reconnectInterval = setInterval(() => {
      if (!this.socket?.isOpen()) {
        this.connect();
      } else {
        this.clearReconnectInterval();
      }
    }, 5000);
  }

  clearReconnectInterval() {
    console.log("clearReconnectInterval", this.reconnectInterval);
    clearInterval(this.reconnectInterval);
  }

  setListener() {
    if (!this.socket) return;
    this.socket.addListener(WebSocketEvent.OPEN, this.openHandler);
    this.socket.addListener(WebSocketEvent.CLOSE, this.closeHandler);
    this.socket.addListener(WebSocketEvent.MESSAGE, this.messageHandler);
    this.socket.addListener(WebSocketEvent.ERROR, this.errorHandler);
  }

  sendMessage(message: any) {
    if (this.socket && this.socket.isOpen()) {
      this.socket.send(message);
    }
  }

  removeListeners = () => {
    if (!this.socket) return;

    this.socket.removeListener(WebSocketEvent.OPEN, this.openHandler);
    this.socket.removeListener(WebSocketEvent.MESSAGE, this.closeHandler);
    this.socket.removeListener(WebSocketEvent.ERROR, this.messageHandler);
    this.socket.removeListener(WebSocketEvent.CLOSE, this.errorHandler);
  };

  errorHandler = () => {
    console.error("error");
  };

  closeHandler = (event: Event) => {
    this.options.socketCloseHandler(event, this.dispatch);
    // this.reconnect();
  };

  messageHandler = (event: Event) => {
    this.options.socketMessageHandler(event, this.dispatch);
  };

  openHandler = (event: Event) => {
    this.options.socketOpenHandler(event, this.dispatch);
  };
}
