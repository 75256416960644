import axios from "axios";

export const sendPostRequest = (apiUrl: string, data: any) => {
  return new Promise((resolve, reject) => {
    axios
      .post(apiUrl, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const sendGetRequest = (apiUrl: string, params: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .get(apiUrl, {
        params,
        ...{
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        },
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          //   window.location = "/login";
          reject(error);
        } else {
          reject(error);
        }
      });
  });
};
