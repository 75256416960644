import { sendGetRequest } from "../../../utils/api";
import { config } from "../../../utils/config";
import { HolderType } from "../../types/Holder.types";
import { SubscriberRange } from "../../types/Token.types";

export function getNewTokenList(
  page: number,
  perPage: number = 20
): Promise<any> {
  return sendGetRequest(`${config.API_URL}/token/listing`, {
    page,
    perPage,
  });
}

export function getBurnedTokenList(
  page: number,
  perPage: number = 20
): Promise<any> {
  return sendGetRequest(`${config.API_URL}/token/burn`, {
    page,
    perPage,
  });
}

export function getDexscreenerUpdateList(
  page: number,
  perPage: number = 20
): Promise<any> {
  return sendGetRequest(`${config.API_URL}/token/social-update`, {
    page,
    perPage,
  });
}

export function getTokenDetail(token: string): Promise<any> {
  return sendGetRequest(`${config.API_URL}/token/get-detail/${token}`, {});
}

export function getSimilarHolderList(token: string, type: HolderType): Promise<any> {
  return sendGetRequest(`${config.API_URL}/token/get-similar-holders`, {
    token,
    type,
  });
}

export function getTrendingTokenList(): Promise<any> {
  return sendGetRequest(`${config.API_URL}/token/trending`, {});
}

export function getChannelCallList(
  channelSize: SubscriberRange,
  page: number,
  perPage: number = 20,
): Promise<any> {
  return sendGetRequest(`${config.API_URL}/channel/get-latest-calls`, {
    channelSize,
    page,
    perPage,
  });
}