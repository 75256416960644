import { timeStamp } from "console";

export const generateDexscreenerUrl = (token: string): string => {
  return `https://dexscreener.com/solana/${token}`;
};
export const generatePhotonUrl = (token: string): string => {
  return `https://photon-sol.tinyastro.io/en/lp/${token}`;
};

export const formatNumber = (value: string | number, decimalPlaces = 0) => {
  if (typeof value === "string") {
    value = parseFloat(value.replace(/,/g, ""));
  }

  if (isNaN(value)) {
    return "Invalid number";
  }

  let options = {
    minimumFractionDigits: decimalPlaces || 0,
    maximumFractionDigits: decimalPlaces || 0,
  };

  return value.toLocaleString(undefined, options);
};

export const convertTimestampToDateString = (
  timeStamp: number | string,
): string => {
  const date = new Date(+timeStamp * 1000);

  // Format the date to m-d-y hh:ii:Ss
  const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}-${date.getFullYear()} ${date
    .getHours()
    .toString()
    .padStart(
      2,
      "0",
    )}:${date.getMinutes().toString().padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
  return formattedDate;
};

export const convertUTCToLocaleTime = (
  utcDateStr: string | undefined,
): string => {
  if (!utcDateStr) return "";
  // Create a Date object from the UTC string
  const date = new Date(utcDateStr);

  // Format the date to m-d-y hh:ii:Ss
  const formattedDate = `${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date
    .getDate()
    .toString()
    .padStart(2, "0")}-${date.getFullYear()} ${date
    .getHours()
    .toString()
    .padStart(2, "0")}:${date
    .getMinutes()
    .toString()
    .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
  return formattedDate;
};

export function calculateTimeAgo(utcDateStr: string) {
  const date = new Date(utcDateStr);
  const now = new Date();
  const diffInMilliseconds = now.getTime() - date.getTime(); // Difference in milliseconds
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000); // Convert to seconds

  const units = [
    { name: "year", seconds: 31536000 },
    { name: "month", seconds: 2592000 },
    { name: "week", seconds: 604800 },
    { name: "day", seconds: 86400 },
    { name: "hour", seconds: 3600 },
    { name: "minute", seconds: 60 },
    { name: "second", seconds: 1 },
  ];

  for (const unit of units) {
    const quotient = Math.floor(diffInSeconds / unit.seconds);
    if (quotient > 0) {
      return quotient === 1
        ? `1 ${unit.name} ago`
        : `${quotient} ${unit.name}s ago`;
    }
  }

  return "just now";
}
