import { ErrorMessage, Field, Form, Formik } from "formik";
import { object, string } from "yup";
import { sendContactUsForm } from "./ContactUsApi";
import { toast } from "react-toastify";

const contactUsSchema = object({
  email: string().email().required(),
  subject: string().required(),
  message: string().required(),
});
export default function ContactUs() {
  const onSubmit = async (
    values: { email: string; subject: string; message: string },
    {
      setSubmitting = (isSubmitting: boolean): void => {},
      resetForm = (): void => {},
    },
  ) => {
    const response = await sendContactUsForm(values);
    if (response.status === 200) {
      toast.success(response.data.data);
      resetForm();
    } else {
      toast.error(response.message);
    }

    setSubmitting(false);
  };
  return (
    <div className="pt-2 lg:pr-80 xl:min-w-0">
      <div className="flex min-h-screen flex-1 flex-col">
        <div className="mx-auto mt-5 rounded-2xl border-2 border-gray-800 bg-zinc-900/10 p-4 text-white hover:border-2 hover:border-dashed hover:border-cyan-600 sm:min-w-full xl:min-w-[50%]">
          <h1 className="text-4xl">Contact Us</h1>
          <Formik
            initialValues={{ email: "", subject: "", message: "" }}
            validationSchema={contactUsSchema}
            onSubmit={(values, { setSubmitting, resetForm }) =>
              onSubmit(values, { setSubmitting, resetForm })
            }
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mt-3">
                  <ErrorMessage
                    name="email"
                    component="span"
                    className="text-xs text-red-600"
                  />
                  <Field
                    className="block flex-1 rounded-xl border border-gray-800 bg-zinc-900/10 p-1 px-2 text-white placeholder:text-gray-400 focus:border-cyan-600 focus:bg-zinc-900/10 sm:text-sm sm:leading-6"
                    type="email"
                    name="email"
                    placeholder="Email address"
                  />
                </div>
                <div className="mt-3">
                  <ErrorMessage
                    name="subject"
                    component="span"
                    className="text-xs text-red-600"
                  />
                  <Field
                    className="block flex-1 rounded-xl border border-gray-800 bg-zinc-900/10 p-1 px-2 text-white placeholder:text-gray-400 focus:border-cyan-600 sm:text-sm sm:leading-6"
                    type="text"
                    name="subject"
                    placeholder="Subject"
                  />
                </div>
                <div className="mt-3">
                  <ErrorMessage
                    name="message"
                    component="span"
                    className="text-xs text-red-600"
                  />
                  <Field
                    component="textarea"
                    name="message"
                    rows="5"
                    className="block w-full flex-1 rounded-xl border border-gray-800 bg-zinc-900/10 p-1 px-2 text-white placeholder:text-gray-400 focus:border-cyan-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <div className="mt-3">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-indigo-300"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
