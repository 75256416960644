import {
  ArrowTrendingUpIcon,
  CurrencyDollarIcon,
  FireIcon,
  InformationCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";

const items = [
  // {
  //   title: "New Token",
  //   path: "/token/new",
  // },
  {
    icon: <FireIcon className="h-4 w-4 text-red-500" />,
    title: "Burn Token",
    path: "/solana/burn",
  },
  {
    icon: <InformationCircleIcon className="h-4 w-4 text-cyan-500" />,
    title: "Dexscreener Update",
    path: "/solana/dexscreener-update",
  },
  {
    icon: <InformationCircleIcon className="h-4 w-4 text-blue-500" />,
    title: "Dexscreener CTO",
    path: "/solana/dexscreener-cto",
  },
  {
    icon: <ArrowTrendingUpIcon className="h-4 w-4 text-green-500" />,
    title: "Solana Trending",
    path: "/solana/trending",
  },
  {
    icon: <UserGroupIcon className="h-4 w-4 text-purple-500" />,
    title: "Group Call",
    path: "/solana/group-call/all",
    submenu: [
      {
        title: "0-5000",
        path: "/solana/group-call/small?page=1",
      },
      {
        title: "5000-10000",
        path: "/solana/group-call/medium?page=1",
      },
      {
        title: "10000-20000",
        path: "/solana/group-call/large?page=1",
      },
      {
        title: "20000-30000",
        path: "/solana/group-call/very-large?page=1",
      },
      {
        title: "> 30000",
        path: "/solana/group-call/huge?page=1",
      },
    ],
  },
  {
    icon: <CurrencyDollarIcon className="h-4 w-4 text-yellow-500" />,
    title: "Airdrop",
    path: "/solana/airdrop",
  },
];

const Sidebar = () => {
  return (
    <div className="flex md:h-screen">
      <div className="relative space-y-6 text-white lg:text-sm lg:leading-6">
        <h5 className="text-xs font-semibold text-white">Menu</h5>
        <div className="relative mt-3">
          <ul className="border-l border-slate-800">
            {items.map((item) => (
              <li className="relative" key={item.path}>
                <NavLink
                  to={item.path}
                  className={({ isActive, isPending }) => {
                    const cl =
                      "flex items-start gap-2 border-l-2 py-1 pl-4 pr-3 text-sm text-slate-400 transition hover:border-green-500 hover:text-slate-300";
                    return `${cl} ${
                      isPending
                        ? "pending"
                        : isActive
                          ? "border-green-500"
                          : "border-transparent"
                    }`;
                  }}
                >
                  {item.icon && item.icon} <span>{item.title}</span>
                </NavLink>
                {item.submenu &&
                  item.submenu.map((item) => {
                    return (
                      <p
                        className="submenu-item relative pl-7"
                        key={item.path}
                      >
                        <NavLink
                          to={item.path}
                          className="flex items-start gap-2 py-1 pl-4 pr-3 text-xs text-slate-400 transition hover:border-green-500 hover:text-slate-300"
                        >
                          <span>{item.title}</span>
                        </NavLink>
                      </p>
                    );
                  })}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
