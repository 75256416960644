import React from "react";
import {
  LightBulbIcon,
  BeakerIcon,
  CurrencyDollarIcon,
  FireIcon,
  CubeIcon,
  UsersIcon,
  BuildingOfficeIcon,
} from "@heroicons/react/24/solid";
import TokenPoolInformationInterface from "../../types/TokenPoolInformation.types";
import { formatNumber } from "../../../utils/utils";

interface TokenPoolInformationProps {
  poolInformation?: TokenPoolInformationInterface;
  totalSupply?: number;
}

const TokenPoolInformation = (props: TokenPoolInformationProps) => {
  const { poolInformation, totalSupply } = props;

  return (
    <div className="flex flex-col px-4 text-sm text-white">
      {poolInformation && poolInformation.marketCap && (
        <div className="flex items-center">
          <LightBulbIcon className="mr-2 h-4 w-4 text-yellow-500" />
          <span className="mr-2 pl-1 font-semibold">Market Cap:</span>$
          {formatNumber(poolInformation.marketCap)}
        </div>
      )}
      {poolInformation && poolInformation.liquidSupply && (
        <div className="flex items-center">
          <BeakerIcon className="mr-2 h-4 w-4 text-blue-500" />
          <span className="mr-2 pl-1 font-semibold">Liquidity:</span>$
          {formatNumber(poolInformation.liquidSupply)}
        </div>
      )}
      {poolInformation && poolInformation.tokenPrice && (
        <div className="flex items-center">
          <CurrencyDollarIcon className="mr-2 h-4 w-4 text-green-500" />
          <span className="mr-2 pl-1 font-semibold">Token Price:</span>$
          {poolInformation.tokenPrice.toFixed(7)}
        </div>
      )}
      {poolInformation && poolInformation.solInPool && (
        <div className="flex items-center">
          <FireIcon className="mr-2 h-4 w-4 text-red-500" />
          <span className="mr-2 pl-1 font-semibold">Pooled SOL:</span>
          {formatNumber(poolInformation.solInPool, 1)} SOL
        </div>
      )}
      {poolInformation && poolInformation.solInPoolRate && (
        <div className="flex items-center">
          <FireIcon className="mr-2 h-4 w-4 text-red-500" />
          <span className="mr-2 pl-1 font-semibold">Pooled SOL Rate:</span>
          {formatNumber(poolInformation.solInPoolRate)}%
        </div>
      )}
      {poolInformation && poolInformation.initialSolInPool && (
        <div className="flex items-center">
          <FireIcon className="mr-2 h-4 w-4 text-cyan-500" />
          <span className="mr-2 pl-1 font-semibold">Initial Pooled SOL:</span>
          {formatNumber(poolInformation.initialSolInPool)} SOL
        </div>
      )}
      {poolInformation && poolInformation.initialSolInPoolRate && (
        <div className="flex items-center">
          <FireIcon className="mr-2 h-4 w-4 text-cyan-500" />
          <span className="mr-2 pl-1 font-semibold">
            Initial Pooled SOL Rate:
          </span>
          {formatNumber(poolInformation.initialSolInPoolRate)}%
        </div>
      )}
      {totalSupply && (
        <div className="flex items-center">
          <CubeIcon className="mr-2 h-4 w-4 text-yellow-500" />
          <span className="mr-2 pl-1 font-semibold">Total Supply:</span>{" "}
          {formatNumber(totalSupply)}
        </div>
      )}
      {/* <div className="flex items-center">
        <CubeIcon className="h-4 w-4 text-yellow-500 mr-2" />
        <span className="font-semibold pl-1 mr-2">Liquid Supply:</span> 100.00%
      </div> */}
      {poolInformation && poolInformation.currentHoldersCount && (
        <div className="flex items-center">
          <UsersIcon className="mr-2 h-4 w-4 text-purple-500" />
          <span className="mr-2 pl-1 font-semibold">Holders:</span>
          {poolInformation && formatNumber(poolInformation.currentHoldersCount)}
        </div>
      )}
      {poolInformation &&
        poolInformation.topHolders &&
        poolInformation.topHolders.length > 0 && (
          <div className="flex items-center">
            <BuildingOfficeIcon className="mr-2 h-4 w-4 text-gray-700" />
            <span className="mr-2 pl-1 font-semibold">Top holders:</span>
            {poolInformation.topHolders.join(", ")}
          </div>
        )}
    </div>
  );
};

export default TokenPoolInformation;
