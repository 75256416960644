import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDexscreenerUpdateListRequest,
  selectDexscreenerUpdateStateState,
} from "../features/token-list/newTokenSlice";
import { UnknownAction } from "@reduxjs/toolkit";
import DexscreenerUpdateTokenList from "../features/token-list/dexscreener/DexscreenerUpdateTokenList";
import NavigationComponent from "../features/token-list/components/NavigationComponent";
import LoadingPlaceholderComponent from "../features/token-list/components/LoadingPlaceholderComponent";
import ErrorAndReloadButtonComponent from "../features/token-list/components/ErrorAndReloadButtonComponent";

export default function DexscreenerUpdatePage() {
  const dispatch = useDispatch();
  const state = useSelector(selectDexscreenerUpdateStateState);

  useEffect(() => {
    dispatch(
      fetchDexscreenerUpdateListRequest({
        ...state,
      }) as unknown as UnknownAction,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (page: number) => {
    dispatch(
      fetchDexscreenerUpdateListRequest({
        ...state,
        ...{ page },
      }) as unknown as UnknownAction,
    );
  };

  const handleReload = () => {
    dispatch(
      fetchDexscreenerUpdateListRequest({
        ...state,
      }) as unknown as UnknownAction
    );
  };

  return (
    <>
      {state.isLoading ? (
        <LoadingPlaceholderComponent />
      ) : state.error ? (
        <ErrorAndReloadButtonComponent onReload={handleReload} />
      ) : (
        <>
          <DexscreenerUpdateTokenList data={state ? state.data : []} />
          <NavigationComponent
            selectPagingData={selectDexscreenerUpdateStateState}
            handleChangePage={handleChangePage}
          />
        </>
      )}
    </>
  );
}
