/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useCallback } from "react";
import {
  NotificationDisplayType,
  NotificationStatus,
  selectNotificationState,
  setDisplayType,
  setShowNotification,
  setCurrentPath,
  setShowReload,
  getNotificationPath,
} from "./notificationSlice";
import { NotificationType } from "./Notification.types";
import TimeAgoComponent from "./TimeAgoComponent";
import { useMatchMedia } from "../../utils/useMatchMedia";
import { Link } from "react-router-dom";
import { ArrowPathIcon } from "@heroicons/react/24/solid";

const notificationLabel = {
  all: "All",
  new_burn_token: "Burn",
  new_dexscreener_update: "DexUpdate",
  new_trending_update: "Trending",
  new_cto_token: "CTO",
};

const getTokenDetailPath = (token: string) => `/solana/token/${token}`;

const notificationMessage = {
  new_burn_token: (token: string, tokenName: string) => (
    <Link to={getTokenDetailPath(token)}>
      Token <strong className="text-blue-400">{tokenName}</strong> was burned
    </Link>
  ),
  new_dexscreener_update: (token: string, tokenName: string) => (
    <Link to={getTokenDetailPath(token)}>
      DexUpdate for token <strong className="text-blue-400">{tokenName}</strong>{" "}
      was successfully updated
    </Link>
  ),
  new_trending_update: (token: string, tokenName: string) => (
    <Link to={getTokenDetailPath(token)}>
      Token <strong className="text-blue-400">{tokenName}</strong> is on
      trending
    </Link>
  ),
  new_cto_token: (token: string, tokenName: string) => (
    <Link to={getTokenDetailPath(token)}>
      Dexscreener CTO was found for token{" "}
      <strong className="text-blue-400">{tokenName}</strong>
    </Link>
  ),
};

const notificationColors = {
  new_burn_token: "bg-red-500",
  new_dexscreener_update: "bg-blue-500",
  new_trending_update: "bg-green-500",
  new_cto_token: "bg-yellow-500",
};

const notificationStyles = {
  all: {
    background: "bg-purple-50",
    textColor: "text-purple-700",
    ringColor: "ring-purple-600/10",
  },
  new_burn_token: {
    background: "bg-red-50",
    textColor: "text-red-700",
    ringColor: "ring-red-600/10",
  },
  new_dexscreener_update: {
    background: "bg-blue-50",
    textColor: "text-blue-600",
    ringColor: "ring-blue-500/10",
  },
  new_trending_update: {
    background: "bg-green-50",
    textColor: "text-green-800",
    ringColor: "ring-green-600/20",
  },
  new_cto_token: {
    background: "bg-yellow-50",
    textColor: "text-yellow-700",
    ringColor: "ring-yellow-600/20",
  },
};

export default function NotificationComponent() {
  const { data, status, displayType, showNotification, showReload } =
    useSelector(selectNotificationState);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const isDesktopResolution = useMatchMedia("(min-width:1024px)", true);

  useEffect(() => {
    dispatch(setCurrentPath(location.pathname));
  }, [dispatch, location.pathname]);

  const handleReload = useCallback(() => {
    navigate("/refresh");
    navigate(-1);
    dispatch(setShowReload(false));
  }, [dispatch]);

  useEffect(() => {
    if (isDesktopResolution) {
      dispatch(setShowNotification(true));
    } else {
      dispatch(setShowNotification(false));
    }
  }, [isDesktopResolution]);

  useEffect(() => {
    dispatch({ type: "socket/connect" });

    return () => {
      dispatch({ type: "socket/disconnect" });
    };
  }, []);

  useEffect(() => {
    if (status === NotificationStatus.CONNECTED) {
      dispatch({ type: "socket/getLatestNotifications" });
    } else if (status === NotificationStatus.DISCONNECTED) {
      dispatch({ type: "socket/reconnect" });
    }
  }, [status]);

  const generateMessage = (
    type: NotificationType,
    tokenAddress: string,
    tokenName: string,
  ) => {
    const messageTemplate = notificationMessage[type];
    return messageTemplate ? messageTemplate(tokenAddress, tokenName) : null;
  };

  const getLabelColor = (type: NotificationType) => {
    return notificationColors[type] || "bg-gray-500"; // Default color if type is not found
  };

  const renderNotificationLabel = (
    type: NotificationDisplayType,
    activeType: string,
  ) => {
    const { background, textColor, ringColor } = notificationStyles[type] || {};
    return (
      <div
        key={type}
        className={`inline-flex border-b-2 pb-1 ${activeType === type ? "border-b-blue-400" : "border-b-transparent"}`}
      >
        <span
          className={`cursor-pointer items-center rounded-md border-b-2 px-2 py-1 text-xs font-medium ring-1 ring-inset ${background} ${textColor} ${ringColor}`}
          onClick={() => dispatch(setDisplayType(type))}
        >
          {notificationLabel[type]}
        </span>
      </div>
    );
  };

  return (
    <>
      <div
        className={`${showNotification ? "opacity-1 w-80" : "w-0 opacity-0"} fixed inset-y-0 right-0 border-l-blue-400/30 backdrop-blur transition-all sm:border-l`}
      >
        <div className="relative flex h-full min-h-0 flex-col pt-14">
          <div className="flex w-full gap-2 border-b border-b-gray-800 p-4 text-center">
            {Object.keys(notificationLabel).map((item) => {
              return renderNotificationLabel(
                item as NotificationDisplayType,
                displayType,
              );
            })}
          </div>
          <div className="flex grow flex-col overflow-y-scroll px-4">
            {data &&
              data.length > 0 &&
              data.map((row, key) =>
                displayType === "all" || displayType === row.type ? (
                  <div
                    key={key}
                    className="flex cursor-pointer flex-col gap-2 border-b border-b-gray-800 py-2"
                    onClick={() => {
                      navigate(getTokenDetailPath(row.data.token));
                    }}
                  >
                    <div className="flex grow flex-row">
                      <span
                        className={`items-center rounded-md px-1 py-0.5 text-sm/5 font-bold text-white max-sm:hidden forced-colors:outline ${getLabelColor(
                          row.type,
                        )}`}
                      >
                        {notificationLabel[row.type]}
                      </span>
                      <span className="ml-auto items-end justify-end text-sm text-zinc-400">
                        <TimeAgoComponent date={row.dataTime} />
                      </span>
                    </div>
                    <p className="text-sm text-white">
                      {generateMessage(row.type, row.data.token, row.data.name)}
                    </p>
                  </div>
                ) : (
                  <></>
                ),
              )}
          </div>
          <div className="flex items-end justify-end p-4">
            {status === NotificationStatus.CONNECTED && (
              <div className="w-full justify-center rounded-xl bg-green-400 py-0 text-center">
                <span className="text-sm font-bold text-white">Online</span>
              </div>
            )}
            {status === NotificationStatus.DISCONNECTED && (
              <div className="w-full justify-center rounded-xl bg-red-400 py-0 text-center">
                <span className="text-sm font-bold text-white">Offline</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {showReload && (
        <div className="fixed inset-x-0 bottom-4 flex justify-center">
          <button
            onClick={handleReload}
            className="fixed left-1/2 top-16 z-50 inline-flex -translate-x-1/2 transform rounded-full bg-blue-500 px-4 py-2 text-sm font-bold text-white shadow-lg hover:bg-blue-600"
          >
            <ArrowPathIcon className="mr-2 h-5 w-5" />
            <span>Reload</span>
          </button>
        </div>
      )}
    </>
  );
}
