import { Dispatch } from "@reduxjs/toolkit";
import { config } from "../config";
import {
  socketMessageHandler,
  socketCloseHandler,
  socketOpenHandler,
} from "./socketMessageHandler";
import { SocketService } from "./SocketService";
import { RootState } from "../../app/store";

export const socketMiddleware =
  () => (params: { dispatch: Dispatch; getState: () => RootState }) => {
    const { dispatch } = params;

    const socketService = new SocketService(config.WS_URL, dispatch, {
      socketMessageHandler,
      socketCloseHandler,
      socketOpenHandler,
    });
    return (next: (action: any) => any) => (action: { type: string }) => {
      const { type } = action;
      switch (type) {
        case "socket/connect":
          socketService.connect();
          break;

        case "socket/reconnect":
          socketService.reconnect();
          break;

        case "socket/getLatestNotifications":
          socketService.sendMessage({ action: "getLatestNotifications" });
          break;

        case "socket/disconnect":
          socketService.disconnect();
          break;

        default:
          break;
      }

      return next(action);
    };
  };
