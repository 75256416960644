import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../features/sidebar/Sidebar";
import { useEffect, useState } from "react";
import { HeaderTemplate, HeaderTemplateType } from "../layout/HeaderTemplate";
import { pageTitle } from "../constants/PageTitle";
export function TokenPage() {
  const location = useLocation();

  const [title, setTitle] = useState({} as HeaderTemplateType);
  useEffect(() => {
    console.log(location);
    const titles = Object.values(pageTitle) as HeaderTemplateType[];
    const curTitle = titles.find(
      (item: HeaderTemplateType) => item.path === location.pathname,
    );
    if (curTitle && curTitle.title) {
      setTitle(curTitle);
    }
  }, [location]);
  return (
    <>
      <div className="flex flex-1 flex-col pb-2 xl:min-w-0 xl:pr-80 lg:pt-2">
        <header className="">
          <div className="mx-auto w-full px-2 py-4 lg:px-0">
            <HeaderTemplate {...title} />
          </div>
        </header>
        <div className="mx-auto flex w-full flex-col gap-4 py-2 sm:flex-row xl:gap-0">
          <div className="md:basis-1/4 xl:mx-auto xl:basis-1/6">
            <Sidebar />
          </div>
          <div className="md:basis-3/4 xl:mx-auto xl:basis-5/6">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
