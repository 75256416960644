import { TelegramCallResponse } from "../../types/Token.types";
import { ClockIcon } from "@heroicons/react/24/outline";
import { MutableRefObject, useRef, useState } from "react";
import { convertTimestampToDateString, formatNumber } from "../../../utils/utils";
import { UserIcon } from "@heroicons/react/24/solid";

interface ChannelCallListProps {
    peers: TelegramCallResponse['peers'];
}
export default function ChannelCallDetail(props: ChannelCallListProps) {
    const [peers, setPeers] = useState(props.peers);
    type SortType = { sortType: number; sortOrder: number };

    const sort: MutableRefObject<SortType> = useRef<SortType>({ sortOrder: 0, sortType: 0 });

    const sortPeers = (peers: TelegramCallResponse["peers"], sortType: number, sortOrder: number): TelegramCallResponse['peers'] => {
        const sortedPeers = [...peers];
        if (sortType === 1) {
            // Sort by subscribers
            sortedPeers.sort((a, b) => (b.subscribers - a.subscribers) * sortOrder);
        } else if (sortType === 2) {
            // Sort by dateTime
            sortedPeers.sort((a, b) => (b.dataTime - a.dataTime) * sortOrder);
        }
        return sortedPeers;
    };

    const updateSortPeers = (sortType: number) => {
        sort.current.sortOrder = sort.current.sortOrder === 1 ? -1 : 1;
        sort.current.sortType = sortType;
        const sortedPeers = sortPeers([...peers], sort.current.sortType, sort.current.sortOrder);
        setPeers(sortedPeers);
        // sort.current[call._id] = { sortOrder, sortType };
        // dispatch(updateChannelCall({ ...call, peers: sortedPeers }));

    }

    return (

        <div className="flex flex-col text-sm text-white">
            {peers && (
                <div className="flex flex-col">
                    <div className="flex">
                        <span className="mr-4 font-semibold">
                            <span className="flex flex-column md:flex-row gap-2">
                                <button className="flex text-white" onClick={() => { updateSortPeers(1); }}>
                                    <UserIcon className="mr-2 h-5 w-4 text-green-500" /> Sort by subscribers {sort.current.sortType === 1 ? sort.current.sortOrder === 1 ? "↑" : "↓" : ""}
                                </button>
                                <button className="flex text-white" onClick={() => { updateSortPeers(2); }}>
                                    <ClockIcon className="mr-2 h-5 w-4 text-green-500" /> Sort by date {sort.current.sortType === 2 ? sort.current.sortOrder === 1 ? "↑" : "↓" : ""}
                                </button>
                            </span>
                        </span>
                    </div>
                    <div className="mt-2 flex flex-col">
                        {peers.map((peer, key) => (
                            <div key={key} className="flex flex-col gap-2 md:flex-row">
                                <div className="flex flex-row align-middle">
                                    <UserIcon className="mr-2 h-5 w-4 text-yellow-500" />
                                    <span className="mr-2 text-sm">Subscriber:</span>
                                    <span className="min-w-14 text-sm text-green-500">
                                        {formatNumber(peer.subscribers)}
                                    </span>
                                </div>
                                <div className="flex flex-row align-middle">
                                    <ClockIcon className="mr-2 inline h-5 w-4" />
                                    <span className="text-sm">
                                        {convertTimestampToDateString(peer.dataTime)}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>

    );

}
