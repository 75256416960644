export class Socket {
  socket: WebSocket | null;
  constructor() {
    this.socket = null;
  }

  connect(url: string) {
    this.socket = new WebSocket(url);
  }

  disconnect() {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.close();
      this.socket = null;
    }
  }

  send(message: any) {
    if (this.socket) {
      this.socket.send(
        typeof message === "string" ? message : JSON.stringify(message),
      );
    }
  }

  addListener(eventName: string, callback: EventListenerOrEventListenerObject) {
    if (this.socket) {
      this.socket.addEventListener(eventName, callback);
    }
  }

  removeListener(
    eventName: string,
    callback: EventListenerOrEventListenerObject,
  ) {
    if (this.socket) {
      this.socket.removeEventListener(eventName, callback);
    }
  }

  isOpen() {
    return this.socket?.readyState === WebSocket.OPEN;
  }
}
