interface TokenBasicInformationProps {
  name?: string;
  symbol?: string;
  token: string;
}
export function TokenBasicInformation(props: TokenBasicInformationProps) {
  const { name, symbol, token } = props;
  return (
    <>
      {/* <CurrencyDollarIcon className="w-10 h-10 inline-block text-cyan-400" /> */}
      <div className="min-w-0 flex-auto">
        <div className="text-sm leading-6">
          <h4 className="text-white">
            {name} | <span className="text-purple-400">{symbol}</span>
          </h4>
        </div>
        <span className="text-sm font-medium inline-block text-green-400 w-60 sm:w-auto truncate">
          {token}
        </span>
      </div>
    </>
  );
}
