import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { selectSimilarHoldersState } from "../newTokenSlice";
import {
  generateDexscreenerUrl,
  generatePhotonUrl,
} from "../../../utils/utils";
import { Link } from "react-router-dom";
export const SimilarHolderComponent = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const state = useSelector(selectSimilarHoldersState);

  return (
    <Dialog
      open={true}
      onClose={() => console.log("Close")}
      className="relative z-10 shadow"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full min-w-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative min-w-full transform overflow-hidden rounded-lg bg-gray-800 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:min-w-16 sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="bg-gray-800 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              {state.isLoading ? (
                <div className="mx-auto w-full animate-pulse rounded-md">
                  <div className="flex space-x-4">
                    <div className="h-10 w-10 rounded-full bg-slate-700"></div>
                    <div className="flex-1 space-y-6 py-1">
                      <div className="h-2 rounded bg-slate-700"></div>
                    </div>
                  </div>
                  <div className="mt-5 space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="col-span-2 h-2 rounded bg-slate-700"></div>
                      <div className="col-span-1 h-2 rounded bg-slate-700"></div>
                    </div>
                    <div className="grid grid-cols-3 gap-4">
                      <div className="col-span-2 h-2 rounded bg-slate-700"></div>
                      <div className="col-span-1 h-2 rounded bg-slate-700"></div>
                    </div>
                  </div>
                </div>
              ) : state.data && state.data.length > 0 ? (
                <>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-cyan-100 sm:mx-0 sm:h-10 sm:w-10">
                      <InformationCircleIcon
                        aria-hidden="true"
                        className="h-6 w-6 text-cyan-600"
                      />
                    </div>
                    <div className="mt-2 text-center sm:ml-4 sm:mt-2 sm:text-left">
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold leading-6 text-white"
                      >
                        Similar Holder List
                      </DialogTitle>
                    </div>
                  </div>
                  <ul className="mt-2 divide-y divide-white/5">
                    {state.data.map((row) => {
                      return (
                        <li key={row._id} className="flex justify-between py-1">
                          <div className="flex min-w-0 gap-x-4">
                            <div className="min-w-0 flex-auto">
                              <p className="text-sm font-semibold leading-6 text-white">
                                {row.token[0].symbol}
                              </p>
                            </div>
                          </div>
                          <div className="shrink-0 justify-center sm:flex sm:flex-row sm:items-end">
                            <span className="mr-1 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                              {row.count}
                            </span>
                            <Link
                              className="mr-1 inline-flex text-sm text-blue-600 underline"
                              to={generateDexscreenerUrl(row.token[0].token)}
                              target="_blank"
                            >
                              DEX
                            </Link>
                            <Link
                              className="inline-flex text-sm text-blue-600 underline"
                              to={generatePhotonUrl(row.token[0].token)}
                              target="_blank"
                            >
                              PHO
                            </Link>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : (
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-red-600"
                    />
                  </div>
                  <div className="mt-2 text-center sm:ml-4 sm:mt-2 sm:text-left">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold leading-6 text-white"
                    >
                      No Data Found!
                    </DialogTitle>
                  </div>
                </div>
              )}
            </div>
            <div className="bg-gray-800 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                data-autofocus
                onClick={() => onClose()}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white sm:mt-0 sm:w-auto"
              >
                Close
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
