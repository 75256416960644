import TokenPoolInformation from "./TokenPoolInformation";
import { TokenDetailInterface } from "../../types/Token.types";
import { TokenListItem } from "./TokenListItem";
import { LinkAndBotComponent } from "../components/LinkAndBotComponent";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { fetchSimilarHolderListRequest } from "../newTokenSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { HolderType } from "../../types/Holder.types";
import { UnknownAction } from "@reduxjs/toolkit";
import { convertUTCToLocaleTime } from "../../../utils/utils";
import DexscreenerInformation from "../dexscreener/DexscreenerInformation";
import { TokenTrendingInformation } from "../trending/TokenTrendingInformation";
import ChannelCallDetail from "../calls/ChannelCallDetail";
import { SimilarHolderComponent } from "../components/SimilarHoldersComponent";

interface TokenDataProps {
  data: TokenDetailInterface | null;
  isLoading: boolean;
  error: boolean;
}
export default function TokenDetail(props: TokenDataProps) {
  const token = props.data;
  const [openSimilarHolderDialog, setOpenSimilarHolderDialog] = useState(false);
  const dispatch = useDispatch();
  function doOpenSimilarHolderDialog(token: string, type: HolderType): void {
    console.log("doOpenSimilarHolderDialog", token);
    setOpenSimilarHolderDialog(true);
    dispatch(
      fetchSimilarHolderListRequest({
        token,
        type: type,
      }) as unknown as UnknownAction,
    );
  }
  useEffect(() => {}, []);
  return (
    <div className="">
      {token && (
        <TokenListItem
          token={token}
          date={convertUTCToLocaleTime(token.burnAt)}
          key={token._id}
        >
          <TokenPoolInformation
            poolInformation={
              token.burn
                ? token.burnPoolInformation
                : token.listingPoolInformation
            }
            totalSupply={token.totalSupply}
          />
          {token.dexscreenerinformations && (
            <>
              <div className="mt-4 rounded-lg px-4 text-white">
                <h5 className="mb-2 text-sm font-semibold">Socials:</h5>
              </div>
              <div className="px-2">
                <DexscreenerInformation
                  description={token.dexscreenerinformations.description}
                  {...token.dexscreenerinformations.social}
                ></DexscreenerInformation>
              </div>
            </>
          )}
          {token.calls && (
            <div className="rounded-lg px-4 py-2 text-white">
              <h5 className="mb-2 text-sm font-semibold">Channels:</h5>
              <div className="px-2">
                <ChannelCallDetail peers={token.calls}></ChannelCallDetail>
              </div>
            </div>
          )}
          {token.trendings && (
            <div className="">
              <div className="rounded-lg px-4 text-white">
                <h5 className="text-sm font-semibold">Trending:</h5>
              </div>
              <div className="px-4">
                {token.trendings.map((trending) => (
                  <TokenTrendingInformation
                    data={trending}
                  ></TokenTrendingInformation>
                ))}
              </div>
            </div>
          )}
          <div className="flex flex-col gap-5 py-2 pl-4 pr-4 xl:flex-col">
            <LinkAndBotComponent token={token.token} />
            <div className="mt-2 flex flex-col justify-end gap-2 sm:flex-row xl:mt-0">
              <button
                className={`inline-flex justify-center gap-0.5 overflow-hidden rounded-full border border-transparent bg-cyan-500 px-2 py-1 text-sm font-medium text-white transition`}
                onClick={() =>
                  doOpenSimilarHolderDialog(token.token, HolderType.Burn)
                }
              >
                [Burn] Holders
                <QuestionMarkCircleIcon className="ml-1 h-5 w-5 text-white" />
              </button>
              <button
                className={`inline-flex justify-center gap-0.5 overflow-hidden rounded-full border border-transparent bg-cyan-500 px-2 py-1 text-sm font-medium text-white transition`}
                onClick={() =>
                  doOpenSimilarHolderDialog(
                    token.token,
                    HolderType.DexscreenerUpdate,
                  )
                }
              >
                [Dex] Holders
                <QuestionMarkCircleIcon className="ml-1 h-5 w-5 text-white" />
              </button>
              <button
                className={`inline-flex justify-center gap-0.5 overflow-hidden rounded-full border border-transparent bg-cyan-500 px-2 py-1 text-sm font-medium text-white transition`}
                onClick={() =>
                  doOpenSimilarHolderDialog(token.token, HolderType.TopTrending)
                }
              >
                [Trending] Holders
                <QuestionMarkCircleIcon className="ml-1 h-5 w-5 text-white" />
              </button>
            </div>
          </div>
        </TokenListItem>
      )}
      {openSimilarHolderDialog && (
        <SimilarHolderComponent
          onClose={() => {
            setOpenSimilarHolderDialog(false);
          }}
        />
      )}
    </div>
  );
}
