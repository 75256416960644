import { Dispatch } from "@reduxjs/toolkit";
import { SocketResponse } from "./Socket.types";
import {
  addNotification,
  getLatestNotifications,
  disconnected,
  connected,
} from "../../features/notification/notificationSlice";
import { Notification } from "../../features/notification/Notification.types";

export const socketMessageHandler = (event: Event, dispatch: Dispatch) => {
  const data = "data" in event && event.data ? event.data : null;
  if (data) {
    const parsedData: SocketResponse<Notification|Notification[]> = JSON.parse(data as string);
    switch (parsedData.action) {
      case "addNotification":
        dispatch(addNotification(parsedData.payload as Notification));
        break;
      case "getLatestNotifications":
        dispatch(getLatestNotifications(parsedData.payload as Notification[]));
        break;

      default:
        break;
    }
  }
};

export const socketCloseHandler = (event: Event, dispatch: Dispatch) => {
  console.log("Socket is closed", event);
  dispatch(disconnected());
};


export const socketOpenHandler = (event: Event, dispatch: Dispatch) => {
  console.log("Socket is opened", event);
  dispatch(connected());
};
