import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNewBurnTokenListRequest,
  selectBurnTokenState,
} from "../features/token-list/newTokenSlice";
import { UnknownAction } from "@reduxjs/toolkit";
import TokenList from "../features/token-list/token/TokenList";
import NavigationComponent from "../features/token-list/components/NavigationComponent";
import LoadingPlaceholderComponent from "../features/token-list/components/LoadingPlaceholderComponent";
import ErrorAndReloadButtonComponent from "../features/token-list/components/ErrorAndReloadButtonComponent";

export default function LiquidityBurnPage() {
  const dispatch = useDispatch();
  const state = useSelector(selectBurnTokenState);

  useEffect(() => {
    dispatch(
      fetchNewBurnTokenListRequest({ ...state }) as unknown as UnknownAction,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (page: number) => {
    dispatch(
      fetchNewBurnTokenListRequest({
        ...state,
        ...{ page },
      }) as unknown as UnknownAction,
    );
  };

  return (
    <>
      {state.isLoading ? (
        <LoadingPlaceholderComponent />
      ) : state.error ? (
        <ErrorAndReloadButtonComponent
          onReload={() =>
            dispatch(
              fetchNewBurnTokenListRequest({
                ...state,
              }) as unknown as UnknownAction,
            )
          }
        />
      ) : (
        <>
          <TokenList {...state} />
          <NavigationComponent
            selectPagingData={selectBurnTokenState}
            handleChangePage={handleChangePage}
          />
        </>
      )}
    </>
  );
}
