import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../features/header/Header";
import NotificationComponent from "../features/notification/NotificationComponent";

export function Layout() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Solana Blockchain Token Tracker</title>
      </Helmet>
      <div id="main" className="dark h-screen">
        <div className="min-h-full bg-zinc-900 antialiased">
          <Header />
          <main className="mx-auto p-14 px-2 sm:px-6 lg:px-8">
            <Outlet />
          </main>
          <NotificationComponent />
        </div>
      </div>
    </>
  );
}
