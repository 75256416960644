import { GlowCapture } from "@codaworks/react-glow";
import { TokenListItem } from "../token/TokenListItem";
import TrendingItemInterface from "../../types/TrendingItem.types";
import Collapsible from "../../../layout/Collapsible";
import { TokenTrendingInformation } from "./TokenTrendingInformation";
import { SimilarHolderComponent } from "../components/SimilarHoldersComponent";
import { fetchSimilarHolderListRequest } from "../newTokenSlice";
import { HolderType } from "../../types/Holder.types";
import { UnknownAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { LinkAndBotComponent } from "../components/LinkAndBotComponent";
import { convertUTCToLocaleTime } from "../../../utils/utils";
import { OpenSimilarHolderButtonComponent } from "../components/OpenSimilarHolderButtonComponent";
import { TokenDetailButtonComponent } from "../components/TokenDetailButtonComponent";

interface TrendingTokenListProps {
  data: TrendingItemInterface[];
}

export default function TrendingTokenList(props: TrendingTokenListProps) {
  const { data } = props;
  const [openSimilarHolderDialog, setOpenSimilarHolderDialog] = useState(false);
  const dispatch = useDispatch();
  function doOpenSimilarHolderDialog(token: string): void {
    setOpenSimilarHolderDialog(true);
    dispatch(
      fetchSimilarHolderListRequest({
        token,
        type: HolderType.TopTrending,
      }) as unknown as UnknownAction
    );
  }
  return data && data.length > 0 ? (
    <div className="overflow-y-scroll md:max-h-[75vh]">
      {data.map((trending, key) => (
        <GlowCapture className="overflow-hidden p-1" key={key}>
          <TokenListItem
            token={trending.token[0]}
            date={convertUTCToLocaleTime(trending.dataTime)}
          >
            <div className="flex flex-col gap-1 py-2 pl-4 pr-4 xl:flex-col">
              <div className="mt-2 flex flex-col xl:mt-0">
                <LinkAndBotComponent token={trending.token[0].token} />
                <div className="mt-2">
                  <div className="my-2 mr-2 inline-flex justify-end gap-2 xl:mt-0">
                    <OpenSimilarHolderButtonComponent
                      onClick={() =>
                        doOpenSimilarHolderDialog(trending.token[0].token)
                      }
                    />
                    <TokenDetailButtonComponent
                      token={trending.token[0].token}
                    />
                  </div>
                  <Collapsible title="Trending history">
                    {trending.positions.length > 0 &&
                      trending.positions.map((position) => {
                        return <TokenTrendingInformation data={position} />;
                      })}
                  </Collapsible>
                </div>
              </div>
            </div>
          </TokenListItem>
        </GlowCapture>
      ))}
      {openSimilarHolderDialog && (
        <SimilarHolderComponent
          onClose={() => {
            setOpenSimilarHolderDialog(false);
          }}
        />
      )}
    </div>
  ) : (
    <></>
  );
}
