import {
  configureStore,
  Dispatch,
  ThunkMiddleware,
  Tuple,
  UnknownAction,
} from "@reduxjs/toolkit";
import counterReducer, { CounterState } from "../features/counter/counterSlice";
import tokenListingReducer, {
  TokenListingState,
} from "../features/token-list/newTokenSlice";
import notificationReducer, {
  NotificationState,
} from "../features/notification/notificationSlice";
import { socketMiddleware } from "../utils/socket/socketMiddleware";
const middlewares:
  | Tuple<
      [
        ThunkMiddleware<
          {
            counter: CounterState;
            tokenListing: TokenListingState;
            notification: NotificationState;
          },
          UnknownAction
        >
      ]
    >
  | ((params: {
      dispatch: Dispatch;
      getState: any;
    }) => (next: (arg0: any) => any) => (action: { type: any }) => any)[] = [];
middlewares.push(socketMiddleware());

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    tokenListing: tokenListingReducer,
    notification: notificationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      middlewares as ReturnType<typeof getDefaultMiddleware>
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
