import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTrendingTokenListRequest,
  selectTrendingTokenState,
} from "../features/token-list/newTokenSlice";
import { UnknownAction } from "@reduxjs/toolkit";
import TrendingTokenList from "../features/token-list/trending/TrendingTokenList";
import LoadingPlaceholderComponent from "../features/token-list/components/LoadingPlaceholderComponent";
import ErrorAndReloadButtonComponent from "../features/token-list/components/ErrorAndReloadButtonComponent";

export default function TrendingTokenPage() {
  const dispatch = useDispatch();
  const state = useSelector(selectTrendingTokenState);

  useEffect(() => {
    dispatch(fetchTrendingTokenListRequest() as unknown as UnknownAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {state.isLoading ? (
        <LoadingPlaceholderComponent />
      ) : state.error ? (
        <ErrorAndReloadButtonComponent
          onReload={() =>
            dispatch(
              fetchTrendingTokenListRequest() as unknown as UnknownAction,
            )
          }
        />
      ) : (
        <TrendingTokenList data={state.data || []} />
      )}
    </>
  );
}
