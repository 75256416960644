import { GlowCapture } from "@codaworks/react-glow";
import { useState } from "react";
import DexscreenerInformation from "./DexscreenerInformation";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { UnknownAction } from "@reduxjs/toolkit";
import DexscreenerInformationInterface from "../../types/DexscreenerInformation.types";
import { fetchSimilarHolderListRequest } from "../newTokenSlice";
import { TokenListItem } from "../token/TokenListItem";
import { SimilarHolderComponent } from "../components/SimilarHoldersComponent";
import { HolderType } from "../../types/Holder.types";
import { LinkAndBotComponent } from "../components/LinkAndBotComponent";
import { convertUTCToLocaleTime } from "../../../utils/utils";
import { OpenSimilarHolderButtonComponent } from "../components/OpenSimilarHolderButtonComponent";
import { TokenDetailButtonComponent } from "../components/TokenDetailButtonComponent";

interface DexscreenerUpdateTokenListProps {
  data: DexscreenerInformationInterface[];
}

export default function DexscreenerUpdateTokenList(
  props: DexscreenerUpdateTokenListProps,
) {
  const { data } = props;
  const [openSimilarHolderDialog, setOpenSimilarHolderDialog] = useState(false);
  const dispatch = useDispatch();

  function doOpenSimilarHolderDialog(token: string): void {
    console.log("doOpenSimilarHolderDialog", token);
    setOpenSimilarHolderDialog(true);
    dispatch(
      fetchSimilarHolderListRequest({
        token,
        type: HolderType.DexscreenerUpdate,
      }) as unknown as UnknownAction,
    );
  }

  return data && data.length > 0 ? (
    <div className="overflow-y-scroll md:max-h-[75vh]">
      {data.map((row, index) => (
        <GlowCapture key={index} className="overflow-hidden p-1">
          <TokenListItem
            token={row.token[0]}
            date={convertUTCToLocaleTime(row.dataTime)}
          >
            <DexscreenerInformation
              description={row.description}
              {...row.social}
            ></DexscreenerInformation>
            <div className="flex flex-col gap-1 py-2 pl-4 pr-4 lg:flex-row">
              <LinkAndBotComponent token={row.token[0].token} />
              <div className="mt-2 flex flex-auto justify-end xl:mt-0 gap-2">
                <OpenSimilarHolderButtonComponent
                  onClick={() => doOpenSimilarHolderDialog(row.token[0].token)}
                />
                <TokenDetailButtonComponent token={row.token[0].token} />
              </div>
            </div>
          </TokenListItem>
        </GlowCapture>
      ))}
      {openSimilarHolderDialog && (
        <SimilarHolderComponent
          onClose={() => {
            setOpenSimilarHolderDialog(false);
          }}
        />
      )}
    </div>
  ) : (
    <></>
  );
}
