import { Helmet } from "react-helmet";
export type HeaderTemplateType = {
  title: string;
  description: string;
  path: string;
};
export function HeaderTemplate(props: HeaderTemplateType) {
  const { title, description } = props;
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name={description} />
      </Helmet>
      <h1 className="text-3xl tracking-tight text-white">{title}</h1>
      <h2 className="tracking-tight text-white">
        {description}
      </h2>
    </>
  );
}
