interface TokenStatusProps {
  status?: boolean | undefined;
  label: string;
}
export default function TokenStatus(props: TokenStatusProps) {
  const { status, label } = props;
  return status === undefined ? (
    <></>
  ) : (
    <div className="text-sm leading-6 text-gray-900">
      <div className="mt-1 flex items-center gap-x-1.5">
        <div
          className={`flex-none rounded-full ${
            status ? "bg-emerald-500/20" : "bg-red-500/20"
          } p-1`}
        >
          <div
            className={`h-1.5 w-1.5 rounded-full ${
              status ? "bg-emerald-500" : "bg-red-500"
            }`}
          />
        </div>
        <p className="text-xs leading-5 text-gray-500">{label}</p>
      </div>
    </div>
  );
}
