import TokenPoolInformation from "./TokenPoolInformation";
import TokenInterface from "../../types/Token.types";
import { TokenListItem } from "./TokenListItem";
import { LinkAndBotComponent } from "../components/LinkAndBotComponent";
import { fetchSimilarHolderListRequest } from "../newTokenSlice";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { HolderType } from "../../types/Holder.types";
import { UnknownAction } from "@reduxjs/toolkit";
import { SimilarHolderComponent } from "../components/SimilarHoldersComponent";
import { convertUTCToLocaleTime } from "../../../utils/utils";
import { TokenDetailButtonComponent } from "../components/TokenDetailButtonComponent";
import { OpenSimilarHolderButtonComponent } from "../components/OpenSimilarHolderButtonComponent";

interface TokenListProps {
  data?: TokenInterface[];
  page: number;
  perPage: number;
  totalItems: number;
  totalPages: number;
  isLoading: boolean;
}
export default function TokenList(props: TokenListProps) {
  const { data } = props;
  const [openSimilarHolderDialog, setOpenSimilarHolderDialog] = useState(false);
  const dispatch = useDispatch();
  function doOpenSimilarHolderDialog(token: string): void {
    console.log("doOpenSimilarHolderDialog", token);
    setOpenSimilarHolderDialog(true);
    dispatch(
      fetchSimilarHolderListRequest({
        token,
        type: HolderType.Burn,
      }) as unknown as UnknownAction,
    );
  }
  return (
    <div className="md:max-h-[75vh] overflow-y-scroll">
      {data &&
        data.length > 0 &&
        data.map((token) => {
          return (
            <TokenListItem
              token={token}
              date={convertUTCToLocaleTime(token.burnAt)}
              key={token._id}
            >
              <TokenPoolInformation
                poolInformation={
                  token.burn
                    ? token.burnPoolInformation
                    : token.listingPoolInformation
                }
                totalSupply={token.totalSupply}
              />
              <div className="flex flex-col gap-1 py-2 pl-4 pr-4 xl:flex-row">
                <LinkAndBotComponent token={token.token} />
                <div className="mt-2 flex flex-auto justify-end gap-2 xl:mt-0">
                  <OpenSimilarHolderButtonComponent onClick={() => doOpenSimilarHolderDialog(token.token)} />
                  <TokenDetailButtonComponent token={token.token} />
                </div>
              </div>
            </TokenListItem>
          );
        })}
      {openSimilarHolderDialog && (
        <SimilarHolderComponent
          onClose={() => {
            setOpenSimilarHolderDialog(false);
          }}
        />
      )}
    </div>
  );
}
