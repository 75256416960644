import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNewTokenListRequest,
  selectNewLiquidPoolState,
} from "../features/token-list/newTokenSlice";
import { UnknownAction } from "@reduxjs/toolkit";
import TokenList from "../features/token-list/token/TokenList";
import ErrorAndReloadButtonComponent from "../features/token-list/components/ErrorAndReloadButtonComponent";

export default function NewLiquidityPoolPage() {
  const dispatch = useDispatch();
  const state = useSelector(selectNewLiquidPoolState);

  const handleReload = () => {
    dispatch(
      fetchNewTokenListRequest({ ...state }) as unknown as UnknownAction,
    );
  };

  useEffect(() => {
    handleReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {state.error && <ErrorAndReloadButtonComponent onReload={handleReload} />}
      <TokenList {...state} />
    </>
  );
}
