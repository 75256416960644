import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Notification, NotificationType } from "./Notification.types";

export enum NotificationStatus {
  LOADING = "loading",
  CONNECTED = "connected",
  DISCONNECTED = "disconnected",
}

export type NotificationDisplayType = NotificationType | "all";

export interface NotificationState {
  data: Notification[];
  status: NotificationStatus;
  displayType: NotificationDisplayType;
  showNotification: boolean;
  showReload: boolean;
  currentPath: string;
}

const initialState: NotificationState = {
  data: [],
  status: NotificationStatus.DISCONNECTED,
  displayType: "all",
  showNotification: false,
  showReload: false,
  currentPath: "",
};

export const getNotificationPath = (type: NotificationType): string => {
  switch (type) {
    case "new_burn_token":
      return "/solana/burn";
    case "new_dexscreener_update":
      return "/solana/dexscreener-update";
    case "new_trending_update":
      return "/solana/trending";
    case "new_cto_token":
      return "/solana/dexscreener-cto";
    default:
      return "";
  }
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification, string>) => {
      const notificationPath = getNotificationPath(action.payload.type);
      console.log(state);
      const newState = {
        ...state,
        ...{
          data: [...[action.payload], ...state.data],
          showReload:
            notificationPath === state.currentPath ? true : state.showReload,
        },
      };
      console.log(action.payload);
      console.log(state);
      return newState;
    },
    getLatestNotifications: (
      state,
      action: PayloadAction<Notification[], string>,
    ) => {
      state.data = action.payload ? action.payload : [];
    },
    disconnected: (state) => {
      state.status = NotificationStatus.DISCONNECTED;
    },
    connected: (state) => {
      state.status = NotificationStatus.CONNECTED;
    },
    setDisplayType: (
      state,
      action: PayloadAction<NotificationDisplayType, string>,
    ) => {
      state.displayType = action.payload;
    },
    toggleShowHide: (state) => {
      state.showNotification = !state.showNotification;
    },
    setShowNotification: (state, action: PayloadAction<boolean, string>) => {
      state.showNotification = action.payload;
    },
    setCurrentPath: (state, action: PayloadAction<string>) => {
      state.currentPath = action.payload;
      state.showReload = false; // Reset showReload when path changes
    },
    setShowReload: (state, action: PayloadAction<boolean>) => {
      state.showReload = action.payload;
    },
  },
});

export const {
  addNotification,
  getLatestNotifications,
  disconnected,
  connected,
  setDisplayType,
  toggleShowHide,
  setShowNotification,
  setCurrentPath,
  setShowReload,
} = notificationSlice.actions;
export const selectNotificationState = (state: RootState) => state.notification;
export default notificationSlice.reducer;
