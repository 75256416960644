import React from "react";
import {
  GlobeAltIcon,
  ChatBubbleLeftEllipsisIcon,
  InformationCircleIcon,
  AtSymbolIcon,
} from "@heroicons/react/24/outline";

interface InfoProps {
  description?: string;
  twitter?: string;
  telegram?: string;
  website?: string;
}

const TokenInformation: React.FC<InfoProps> = ({
  description,
  twitter,
  telegram,
  website,
}) => {
  return (
    <div className="px-4 py-2 rounded-lg text-white text-xs">
      {description && (
        <div className="mb-2">
          <p className="font-semibold flex items-center">
            <InformationCircleIcon className="w-4 h-4 inline-block mr-1 text-cyan-400" />
            <span>Description:</span>
          </p>
          <p className="max-w-64 sm:max-w-md lg:max-w-2xl break-words">{description}</p>
        </div>
      )}
      {twitter && (
        <div className="mb-2 flex items-center">
          <AtSymbolIcon className="w-4 h-4 inline-block mr-1 text-cyan-400" />
          <p className="font-semibold mr-2">Twitter:</p>
          <p>
            <a
              href={`${twitter}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400"
            >
              {twitter}
            </a>
          </p>
        </div>
      )}
      {telegram && (
        <div className="mb-2 flex items-center">
          <ChatBubbleLeftEllipsisIcon className="w-4 h-4 inline-block mr-1 text-cyan-400" />
          <p className="font-semibold mr-2">Telegram:</p>
          <p>
            <a
              href={`${telegram}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400"
            >
              {telegram}
            </a>
          </p>
        </div>
      )}
      {website && (
        <div className="mb-2 flex items-center">
          <GlobeAltIcon className="w-4 h-4 inline-block mr-1 text-cyan-400" />
          <p className="font-semibold mr-2">Website:</p>
          <p>
            <a
              href={website}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400"
            >
              {website}
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

export default TokenInformation;
