export const pageTitle = {
  home: {
    title: "Solana Blockchain Token Tracker",
    description: "Solana Blockchain Token Tracker",
    path: "/",
  },
  burn: {
    title: "Solana Blockchain Token Burn Tracker",
    description:
      "Stay updated with the latest token burns on the Solana blockchain. Understand the impact of token burns on the Solana ecosystem.",
    path: "/solana/burn",
  },
  dexscreener_update: {
    title: "Solana DexScreener Information Updated",
    description:
      "Update token information such as Twitter, Telegram, and website on DexScreener.",
    path: "/solana/dexscreener-update",
  },
  dexscreener_update_cto: {
    title: "Solana DexScreener Information Updated - Community Takeover",
    description:
      "Update Community Takeover information of the token (Twitter, Telegram, Website) and CTO information on DexScreener.",
    path: "/solana/dexscreener-cto",
  },
  trending: {
    title: "Solana Trending Token",
    description:
      "Discover the most popular and actively traded tokens on Solana.",
    path: "/solana/trending",
  },
  airdrop: {
    title: "Solana Airdrop",
    description: "Stay informed about upcoming and ongoing airdrops on Solana.",
    path: "/solana/airdrop",
  },
  group_call: {
    title: "Solana Token Called by Groups",
    description:
      "Explore tokens gaining attention from influential groups on Solana.",
    path: "/solana/group-call",
  },
  "contact": {
    title: "Solana Token Called - Contact Us",
    description:
      "Contact us for information",
    path: "/contact-us",
  },
};
