import { ArrowDownCircleIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

interface CollapsibleProps {
  title: string;
  children: React.ReactNode;
}

const Collapsible: React.FC<CollapsibleProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button
        onClick={toggleCollapse}
        className={`border inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition rounded-full py-1 px-3 bg-zinc-800/40 ext-zinc-400 hover:bg-zinc-800 text-zinc-300 hover:text-zinc-300 ${
          isOpen ? "border-cyan-500" : "border-zinc-800"
        }`}
      >
        {title}
        <ArrowDownCircleIcon
          className={`w-5 h-5 ${isOpen ? "text-cyan-500" : ""}`}
        />
      </button>
      <div className="">
        <div
          className={`transition-all duration-300 ease-in-out overflow-hidden ${
            isOpen ? "max-h-screen" : "max-h-0"
          }`}
        >
          <div className="mt-2">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Collapsible;
