import { Dispatch } from "@reduxjs/toolkit";

export enum WebSocketEvent {
  OPEN = "open",
  ERROR = "error",
  CLOSE = "close",
  MESSAGE = "message",
}

export type SocketOptions = {
  socketMessageHandler: (message: Event, dispatch: Dispatch) => void;
  socketCloseHandler: (message: Event, dispatch: Dispatch) => void;
  socketOpenHandler: (message: Event, dispatch: Dispatch) => void;
};

export type SocketResponse<T> = {
  action: string;
  payload: T;
};
