export const LoadingPlaceholderComponent = () => {
  return (
    <div className="flex animate-pulse flex-col gap-y-4">
      {Array.from({ length: 5 }).map((_, index) => (
        <div key={index} className="rounded-2xl border-2 border-gray-800 py-2">
          <div className="flex flex-row justify-between gap-x-4 bg-transparent p-2 px-4">
            <div className="flex min-w-0 gap-x-2">
              <div className="min-w-0 flex-auto">
                <div className="h-2 w-32 bg-slate-700"></div>
                <div className="mt-2">
                  <div className="h-2 w-60 bg-slate-700 sm:w-auto"></div>
                </div>
              </div>
            </div>
            <div className="mt-2 flex flex-col items-start gap-x-2 xl:mt-0 xl:items-end">
              <div>
                <div className="h-2 w-24 bg-slate-700"></div>
              </div>
              <div className="hidden shrink-0 gap-4 sm:flex sm:flex-row sm:items-end">
                <div>
                  <div className="mt-1 flex items-center gap-x-1.5">
                    <div className="bg-emerald-500/20 p-1">
                      <div className="h-1.5 w-1.5 bg-emerald-500"></div>
                    </div>
                    <div className="h-2 w-12 bg-slate-700"></div>
                  </div>
                </div>
                <div>
                  <div className="mt-1 flex items-center gap-x-1.5">
                    <div className="bg-emerald-500/20 p-1">
                      <div className="h-1.5 w-1.5 bg-emerald-500"></div>
                    </div>
                    <div className="h-2 w-12 bg-slate-700"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between gap-y-4 bg-transparent p-2 px-4">
            <div className="h-2 w-72 bg-slate-700"></div>
            <div className="h-2 w-60 bg-slate-700"></div>
            <div className="h-2 w-72 bg-slate-700"></div>
            <div className="h-2 w-60 bg-slate-700"></div>
            <div className="h-2 w-72 bg-slate-700"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LoadingPlaceholderComponent;
