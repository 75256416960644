import { createBrowserRouter, Navigate } from "react-router-dom";
import { TokenPage } from "../pages/TokenPage";
import { Layout } from "../layout/Layout";
import NewLiquidityPoolPage from "../pages/NewLiquidityPoolPage";
import LiquidityBurnPage from "../pages/LiquidityBurnPage";
import NotFoundPage from "../pages/NotFoundPage";
import TrendingTokenPage from "../pages/TrendingTokenPage";
import DexscreenerUpdatePage from "../pages/DexscreenerUpdatePage";
import { ComingSoonPage } from "../pages/ComingSoonPage";
import ChannelCallPage from "../pages/ChannelCallPage";
import ContactUsPage from "../pages/ContactUsPage";
import HomePage from "../pages/HomePage";
import TokenDetailPage from "../pages/TokenDetailPage";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        path: "/",
        element: <HomePage />,
      },
      {
        path: "solana",
        element: <TokenPage />,
        children: [
          { index: true, element: <Navigate to="burn" replace /> },
          {
            path: "new",
            element: <NewLiquidityPoolPage />,
          },
          {
            path: "burn",
            element: <LiquidityBurnPage />,
          },
          {
            path: "dexscreener-update",
            element: <DexscreenerUpdatePage />,
          },
          {
            path: "dexscreener-cto",
            element: <ComingSoonPage />,
          },
          {
            path: "trending",
            element: <TrendingTokenPage />,
          },
          {
            path: "token/:token",
            element: <TokenDetailPage />,
          },
          {
            path: "group-call/:channelSize",
            element: <ChannelCallPage />,
          },
        ],
      },
      {
        path: "ethereum",
        element: <ComingSoonPage />,
      },
      {
        path: "contact-us",
        element: <ContactUsPage />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
      {
        path: "refresh",
        element: <></>,
      },
    ],
  },
]);
export default router;
