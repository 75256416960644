import ArrowRightCircleIcon from "@heroicons/react/24/outline/ArrowRightCircleIcon";
import { Link } from "react-router-dom";

export const TokenDetailButtonComponent = ({ token }: { token: string }) => {
  return (
    <Link
      to={`/solana/token/${token}`}
      className={`inline-flex justify-center gap-0.5 overflow-hidden rounded-full border border-transparent bg-cyan-500 px-2 py-1 text-sm font-medium text-white transition`}
    >
      Detail
      <ArrowRightCircleIcon className="ml-1 h-5 w-5 text-white" />
    </Link>
  );
};
