import React from "react";

interface ErrorComponentProps {
  onReload: () => void;
}

const ErrorAndReloadButtonComponent: React.FC<ErrorComponentProps> = ({
  onReload,
}) => {
  return (
    <div className="flex flex-col items-center justify-center text-white">
      <p>An error occurred while loading the data.</p>
      <button
        onClick={onReload}
        className="mt-4 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      >
        Reload
      </button>
    </div>
  );
};

export default ErrorAndReloadButtonComponent;
