import { ComponentProps, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChannelCallListRequest,
  fetchNewBurnTokenListRequest,
  selectChannelCallState,
} from "../features/token-list/newTokenSlice";
import { UnknownAction } from "@reduxjs/toolkit";
import NavigationComponent from "../features/token-list/components/NavigationComponent";
import LoadingPlaceholderComponent from "../features/token-list/components/LoadingPlaceholderComponent";
import ErrorAndReloadButtonComponent from "../features/token-list/components/ErrorAndReloadButtonComponent";
import { useParams, useSearchParams } from "react-router-dom";
import { SubscriberRange } from "../features/types/Token.types";
import ChannelCallList from "../features/token-list/calls/ChannelCallList";

export default function ChannelCallPage() {
  const dispatch = useDispatch();
  const state = useSelector(selectChannelCallState);
  const [searchParams, setSearchParams] = useSearchParams();
  const { channelSize } = useParams();

  const getSearchParams = (): {
    page?: number;
    perPage?: number;
  } => {
    let params = {};
    if (searchParams.has("page")) {
      params = { ...params, ...{ page: searchParams.get("page") } };
    }
    // if (searchParams.has("channelSize")) {
    //   params = {
    //     ...params,
    //     ...{ channelSize: searchParams.get("channelSize") },
    //   };
    // }
    if (searchParams.has("perPage")) {
      params = { ...params, ...{ perPage: searchParams.get("perPage") } };
    }
    return params;
  };

  useEffect(() => {
    const params: {
      page?: number;
      channelSize?: SubscriberRange;
      perPage?: number;
    } = {
      ...getSearchParams(),
      ...{ channelSize: channelSize as SubscriberRange },
    };

    dispatch(
      fetchChannelCallListRequest({
        ...state,
        ...params,
      }) as unknown as UnknownAction,
    );
  }, [searchParams, channelSize, dispatch]);

  const handleChangePage = (page: number | string) => {
    const params = {
      ...getSearchParams(),
      page: page.toString(),
    };
    setSearchParams({
      ...searchParams,
      ...params,
    });
  };

  return (
    <>
      {state.isLoading ? (
        <LoadingPlaceholderComponent />
      ) : state.error ? (
        <ErrorAndReloadButtonComponent
          onReload={() => handleChangePage(searchParams.get("page") ?? 1)}
        />
      ) : (
        <>
          <ChannelCallList {...state} />
          <NavigationComponent
            selectPagingData={selectChannelCallState}
            handleChangePage={handleChangePage}
          />
        </>
      )}
    </>
  );
}
