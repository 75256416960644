import React, { useEffect, useState } from "react";
import { calculateTimeAgo } from "../../utils/utils";

const TimeAgoComponent = ({ date }: { date: string }) => {
  const [timeAgo, setTimeAgo] = useState("");
  useEffect(() => {
    setTimeAgo(calculateTimeAgo(date));
    const intervalId = setInterval(() => {
      setTimeAgo(calculateTimeAgo(date));
    }, 60000); // update every minute

    return () => clearInterval(intervalId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return <span>{timeAgo}</span>;
};

export default TimeAgoComponent;
