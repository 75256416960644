import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { HeaderTemplate, HeaderTemplateType } from "../layout/HeaderTemplate";
import { pageTitle } from "../constants/PageTitle";
export default function HomePage() {
    const location = useLocation();

    const [title, setTitle] = useState({} as HeaderTemplateType);
    useEffect(() => {
        console.log(location);
        const titles = Object.values(pageTitle) as HeaderTemplateType[];
        const curTitle = titles.find(
            (item: HeaderTemplateType) => item.path === location.pathname,
        );
        if (curTitle && curTitle.title) {
            setTitle(curTitle);
        }
    }, [location]);

    return (
        <>
            <div className="flex flex-1 flex-col pb-2 xl:min-w-0 xl:pr-80 lg:pt-2">
                <header className="">
                    <div className="mx-auto w-full px-2 py-4 lg:px-0">
                        <HeaderTemplate {...title} />
                    </div>
                </header>
                <div className="mx-auto flex w-full flex-col gap-4 px-2 py-4 lg:px-0 sm:flex-row xl:gap-0">
                    <div className="text-white">
                        <h3 className="text-xl mb-4">Monitor new tokens via Telegram channels:</h3>
                        <ul className="list-disc list-inside">
                            <li className="mb-2">
                                <span className="mr-1">Burn & update dexscreener:</span>
                                <a href="https://t.me/jeeterxyz15" target="_blank" className="text-cyan-500 hover:underline">https://t.me/jeeterxyz15</a>
                            </li>
                            <li>
                                <span className="mr-1">Burn & update dexscreener & soltrending:</span>
                                <a href="https://t.me/jeeterxyz14" target="_blank" className="text-cyan-500 hover:underline">https://t.me/jeeterxyz14</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
