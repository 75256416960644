import QuestionMarkCircleIcon from "@heroicons/react/24/outline/QuestionMarkCircleIcon";

export const OpenSimilarHolderButtonComponent = ({ onClick }: { onClick: Function }) => {
  return (
    <button
      className={`inline-flex justify-center gap-0.5 overflow-hidden rounded-full border border-transparent bg-cyan-500 px-2 py-1 text-sm font-medium text-white transition`}
      onClick={() => onClick()}
    >
      Similar Holders
      <QuestionMarkCircleIcon className="ml-1 h-5 w-5 text-white" />
    </button>
  );
};
