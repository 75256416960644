import { TokenBasicInformation } from "./TokenBasicInformation";
import TokenInterface from "../../types/Token.types";
import { ClockIcon } from "@heroicons/react/24/outline";
import TokenStatus from "./TokenStatus";

interface TokenListItemProps {
  token: TokenInterface;
  children: React.ReactNode;
  date?: string;
}
export function TokenListItem(props: TokenListItemProps) {
  const { token, date } = props;
  return (
    <>
      <div className="relative mb-4 flex flex-col justify-between overflow-hidden rounded-2xl border-2 border-gray-800 bg-zinc-900/10 hover:border-2 hover:border-dashed hover:border-cyan-600 glow:border-glow glow:bg-glow/[.25] glow:ring-1 glow:ring-glow">
        <div className="flex flex-col justify-between gap-x-4 bg-transparent p-2 px-4 xl:flex-row">
          <div className="flex min-w-0 gap-x-2">
            <TokenBasicInformation
              name={token.name}
              token={token.token}
              symbol={token.symbol}
            />
          </div>
          <div className="mt-2 flex flex-col items-start gap-x-2 xl:mt-0 xl:items-end">
            <div className="">
              {date !== undefined && (
                <p className="text-xs">
                  <span className="mr-1 text-white">
                    <ClockIcon className="inline-block h-4 w-4" /> Time:
                  </span>
                  <span className="text-green-400">{date}</span>
                </p>
              )}
            </div>
            <div className="hidden shrink-0 gap-4 sm:flex sm:flex-row sm:items-end">
              <TokenStatus status={token.renounced} label="Renounced" />
              <TokenStatus status={token.burn} label="Burned" />
              <TokenStatus status={token.freezeRevoked} label="Freeze" />
            </div>
          </div>
        </div>
        {props.children && props.children}
      </div>
    </>
  );
}
