import {
  Action,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  getBurnedTokenList,
  getChannelCallList,
  getDexscreenerUpdateList,
  getNewTokenList,
  getSimilarHolderList,
  getTokenDetail,
  getTrendingTokenList,
} from "./api/TokenListApi";
import { RootState } from "../../app/store";
import TokenInterface, {
  SubscriberRange,
  TelegramCallResponse,
  TokenDetailInterface,
} from "../types/Token.types";
import TrendingItemInterface from "../types/TrendingItem.types";
import DexscreenerInformationInterface from "../types/DexscreenerInformation.types";
import { HolderType } from "../types/Holder.types";
import { toast } from "react-toastify";

export interface TokenListingState {
  newLiquidPoolState: {
    data: TokenInterface[];
    page: number;
    perPage: number;
    totalItems: number;
    totalPages: number;
    isLoading: boolean;
    error: boolean;
  };
  newBurnTokenState: {
    data: TokenInterface[];
    page: number;
    perPage: number;
    totalItems: number;
    totalPages: number;
    isLoading: boolean;
    error: boolean;
  };
  tokenTrendingState: {
    data: TrendingItemInterface[];
    isLoading: boolean;
    error: boolean;
  };
  tokenDetail: {
    data: TokenDetailInterface | null;
    isLoading: boolean;
    error: boolean;
  };
  dexscreenerUpdateState: {
    data: DexscreenerInformationInterface[];
    page: number;
    perPage: number;
    totalItems: number;
    totalPages: number;
    isLoading: boolean;
    error: boolean;
  };
  similarHolders: {
    data: any[];
    isLoading: boolean;
    error: boolean;
  };
  channelCall: {
    data: TelegramCallResponse[];
    channelSize: SubscriberRange;
    page: number;
    perPage: number;
    totalItems: number;
    totalPages: number;
    isLoading: boolean;
    error: boolean;
  };
}

const initialState: TokenListingState = {
  newLiquidPoolState: {
    data: [],
    page: 1,
    perPage: 20,
    totalItems: 0,
    totalPages: 0,
    isLoading: true,
    error: false,
  },
  newBurnTokenState: {
    data: [],
    page: 1,
    perPage: 20,
    totalItems: 0,
    totalPages: 0,
    isLoading: true,
    error: false,
  },
  tokenDetail: {
    data: null,
    isLoading: true,
    error: false,
  },
  tokenTrendingState: {
    data: [],
    isLoading: true,
    error: false,
  },
  dexscreenerUpdateState: {
    data: [],
    page: 1,
    perPage: 20,
    totalItems: 0,
    totalPages: 0,
    isLoading: true,
    error: false,
  },
  similarHolders: {
    data: [],
    isLoading: true,
    error: false,
  },
  channelCall: {
    data: [],
    channelSize: "all",
    page: 1,
    perPage: 20,
    totalItems: 0,
    totalPages: 0,
    isLoading: true,
    error: false,
  },
};

export const fetchNewTokenListRequest = createAsyncThunk(
  "tokenListing/getTokenList",
  async (
    { page, perPage }: { page: number; perPage: number },
    { rejectWithValue },
  ) => {
    console.log("tokenListing/getTokenList", page, perPage);
    try {
      const response = await getNewTokenList(page, perPage);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const fetchNewBurnTokenListRequest = createAsyncThunk(
  "tokenListing/getBurnTokenList",
  async (
    { page, perPage }: { page: number; perPage: number },
    { rejectWithValue },
  ) => {
    console.log("tokenListing/getBurnTokenList", page, perPage);
    try {
      const response = await getBurnedTokenList(page, perPage);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const fetchTrendingTokenListRequest = createAsyncThunk(
  "tokenListing/getTrendingTokenList",
  async (_, { rejectWithValue }) => {
    console.log("tokenListing/getTrendingTokenList");
    try {
      const response = await getTrendingTokenList();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const fetchDexscreenerUpdateListRequest = createAsyncThunk(
  "tokenListing/getDexscreenerUpdateList",
  async (
    { page, perPage }: { page: number; perPage: number },
    { rejectWithValue },
  ) => {
    console.log("tokenListing/getDexscreenerUpdateList", page, perPage);
    try {
      const response = await getDexscreenerUpdateList(page, perPage);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchTokenDetailRequest = createAsyncThunk(
  "tokenListing/getTokenDetail",
  async ({ token }: { token: string }, { rejectWithValue }) => {
    console.log("tokenListing/getTokenDetail", token);
    try {
      const response = await getTokenDetail(token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchSimilarHolderListRequest = createAsyncThunk(
  "tokenListing/getSimilarHolderList",
  async (
    { token, type }: { token: string; type: HolderType },
    { rejectWithValue },
  ) => {
    console.log("tokenListing/getSimilarHolderList", token);
    try {
      const response = await getSimilarHolderList(token, type);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchChannelCallListRequest = createAsyncThunk(
  "tokenListing/getChannelCallList",
  async (
    {
      channelSize,
      page,
      perPage,
    }: { channelSize: SubscriberRange; page: number; perPage: number },
    { rejectWithValue },
  ) => {
    console.log("tokenListing/getChannelCallList", channelSize, page, perPage);
    try {
      const response = await getChannelCallList(channelSize, page, perPage);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const tokenListingSlice = createSlice({
  name: "tokenListing",
  initialState,
  reducers: {
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    updateChannelCall: (state, action: PayloadAction<TelegramCallResponse>) => {
      const data = state.channelCall.data.map((call) => {
        if (call._id === action.payload._id) {
          return { ...action.payload };
        } else {
          return call;
        }
      });
      state.channelCall.data = [...data];
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchNewTokenListRequest.pending, (state, action) => {
      state.newLiquidPoolState.isLoading = true;
      state.newLiquidPoolState.error = false;
    });
    builder.addCase(fetchNewTokenListRequest.rejected, (state, action) => {
      state.newLiquidPoolState.isLoading = false;
      state.newLiquidPoolState.error = true;
      toast.error("Failed to fetch new token list");
    });
    builder.addCase(fetchNewTokenListRequest.fulfilled, (state, action) => {
      const data = {
        ...state,
        ...{
          newLiquidPoolState: {
            ...state.newLiquidPoolState,
            ...action.payload,
            ...{ isLoading: false, error: false },
          },
        },
      };
      return data;
    });
    builder.addCase(fetchNewBurnTokenListRequest.pending, (state, action) => {
      state.newBurnTokenState.isLoading = true;
      state.newBurnTokenState.error = false;
    });
    builder.addCase(fetchNewBurnTokenListRequest.rejected, (state, action) => {
      state.newBurnTokenState.isLoading = false;
      state.newBurnTokenState.error = true;
      toast.error("Failed to fetch new burn token list");
      console.log("action", action);
    });
    builder.addCase(fetchNewBurnTokenListRequest.fulfilled, (state, action) => {
      const data = {
        ...state,
        ...{
          newBurnTokenState: {
            ...state.newBurnTokenState,
            ...action.payload.data,
            ...{ isLoading: false, error: false },
          },
        },
      };
      return data;
    });
    builder.addCase(fetchTrendingTokenListRequest.pending, (state, action) => {
      state.tokenTrendingState.isLoading = true;
      state.tokenTrendingState.error = false;
    });
    builder.addCase(fetchTrendingTokenListRequest.rejected, (state, action) => {
      state.tokenTrendingState.isLoading = false;
      state.tokenTrendingState.error = true;
      toast.error("Failed to fetch trending token list");
    });
    builder.addCase(
      fetchTrendingTokenListRequest.fulfilled,
      (state, action) => {
        const data = {
          ...state,
          ...{
            tokenTrendingState: {
              ...state.tokenTrendingState,
              ...action.payload.data,
              ...{ isLoading: false, error: false },
            },
          },
        };
        return data;
      },
    );
    builder.addCase(
      fetchDexscreenerUpdateListRequest.pending,
      (state, action) => {
        state.dexscreenerUpdateState.isLoading = true;
        state.dexscreenerUpdateState.error = false;
      },
    );
    builder.addCase(
      fetchDexscreenerUpdateListRequest.rejected,
      (state, action) => {
        state.dexscreenerUpdateState.isLoading = false;
        state.dexscreenerUpdateState.error = true;
        toast.error("Failed to fetch Dexscreener update list");
      },
    );
    builder.addCase(
      fetchDexscreenerUpdateListRequest.fulfilled,
      (state, action) => {
        const data = {
          ...state,
          ...{
            dexscreenerUpdateState: {
              ...state.dexscreenerUpdateState,
              ...action.payload.data,
              ...{ isLoading: false, error: false },
            },
          },
        };
        return data;
      },
    );
    builder.addCase(fetchSimilarHolderListRequest.pending, (state, action) => {
      state.similarHolders.isLoading = true;
      state.similarHolders.error = false;
    });
    builder.addCase(fetchSimilarHolderListRequest.rejected, (state, action) => {
      state.similarHolders.isLoading = false;
      state.similarHolders.error = true;
      toast.error("Failed to fetch similar holder list");
    });
    builder.addCase(
      fetchSimilarHolderListRequest.fulfilled,
      (state, action) => {
        const data = {
          ...state,
          ...{
            similarHolders: {
              ...state.similarHolders,
              ...{ data: action.payload.data },
              ...{ isLoading: false, error: false },
            },
          },
        };
        return data;
      },
    );
    builder.addCase(fetchTokenDetailRequest.pending, (state, action) => {
      state.tokenDetail.isLoading = true;
      state.tokenDetail.error = false;
    });
    builder.addCase(fetchTokenDetailRequest.rejected, (state, action) => {
      state.tokenDetail.isLoading = false;
      state.tokenDetail.error = true;
      toast.error("Failed to token detail");
    });
    builder.addCase(fetchTokenDetailRequest.fulfilled, (state, action) => {
      console.log(action);
      const data = {
        ...state,
        ...{
          tokenDetail: {
            ...state.tokenDetail,
            ...{ data: action.payload.data, isLoading: false, error: false },
          },
        },
      };
      return data;
    });
    builder.addCase(fetchChannelCallListRequest.pending, (state, action) => {
      state.channelCall.isLoading = true;
      state.channelCall.error = false;
    });
    builder.addCase(fetchChannelCallListRequest.rejected, (state, action) => {
      state.channelCall.isLoading = false;
      state.channelCall.error = true;
      toast.error("Failed to fetch channel call list");
    });
    builder.addCase(fetchChannelCallListRequest.fulfilled, (state, action) => {
      const data = {
        ...state,
        ...{
          channelCall: {
            ...state.channelCall,
            ...action.payload.data,
            ...{ isLoading: false, error: false },
          },
        },
      };
      return data;
    });
  },
});

// Action creators are generated for each case reducer function
// export const { getNewTokenList } = tokenListingSlice.actions;
// export const selectPageNumber = (state: TokenListingState) => state.page;
// export const selectPerPageNumber = (state: TokenListingState) => state.perPage;
export const selectNewLiquidPoolState = (state: RootState) =>
  state.tokenListing.newLiquidPoolState;
export const selectBurnTokenState = (state: RootState) =>
  state.tokenListing.newBurnTokenState;
export const selectTrendingTokenState = (state: RootState) =>
  state.tokenListing.tokenTrendingState;
export const selectDexscreenerUpdateStateState = (state: RootState) =>
  state.tokenListing.dexscreenerUpdateState;
export const selectTokenDetailState = (state: RootState) =>
  state.tokenListing.tokenDetail;
export const selectSimilarHoldersState = (state: RootState) =>
  state.tokenListing.similarHolders;
export const selectChannelCallState = (state: RootState) =>
  state.tokenListing.channelCall;
export const { updateChannelCall } = tokenListingSlice.actions;
export default tokenListingSlice.reducer;
