import {
  ArrowPathIcon,
  ArrowTrendingUpIcon,
  BeakerIcon,
  ChartBarIcon,
  ClockIcon,
  CurrencyDollarIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { convertUTCToLocaleTime } from "../../../utils/utils";

interface TokenTrendingInformationProps {
  data: {
    price?: number;
    fdv?: number;
    liquidity?: number;
    trendingPosition?: number;
    age?: string;
    dataTime?: string;
    volume?: {
      h24?: number;
      h6?: number;
      h1?: number;
      m5?: number;
    };
    priceChange?: {
      h24?: number;
      h6?: number;
      h1?: number;
      m5?: number;
    };
    transactions?: {
      total?: number;
      buys?: number;
      sells?: number;
    };
    makers?: {
      total?: number;
      buyers?: number;
      sellers?: number;
    };
  };
}

export const TokenTrendingInformation: React.FC<
  TokenTrendingInformationProps
> = ({ data }) => {
  const getPriceChangeClass = (value?: number) => {
    return value !== undefined && value < 0 ? "text-red-400" : "text-green-400";
  };

  return (
    <div className="p-2 rounded-lg text-white border-b border-white/5 text-xs">
      <div className="flex space-x-4">
        {data.trendingPosition !== undefined && (
          <p className="font-semibold mb-1">
            <ArrowTrendingUpIcon className="w-4 h-4 inline-block mr-1 text-cyan-400" />
            Trending:
            <span className="text-red-400">#{data.trendingPosition}</span>
          </p>
        )}
        {data.dataTime !== undefined && (
          <p className="font-semibold mb-1">
            <ClockIcon className="w-4 h-4 inline-block mr-1 text-cyan-400" />
            Time:{" "}
            <span className="text-green-400">
              {convertUTCToLocaleTime(data.dataTime)}
            </span>
          </p>
        )}
      </div>
      <div className="flex space-x-4">
        {data.price !== undefined && (
          <p className="font-semibold mb-1">
            <CurrencyDollarIcon className="w-4 h-4 inline-block mr-1 text-cyan-400" />
            Price: <span className="text-green-400">{data.price}</span>
          </p>
        )}
        {data.fdv !== undefined && (
          <p className="font-semibold mb-1">
            <BeakerIcon className="w-4 h-4 inline-block mr-1 text-cyan-400" />
            FDV: <span className="text-green-400">{data.fdv}</span>
          </p>
        )}
        {data.liquidity !== undefined && (
          <p className="font-semibold mb-1">
            <BeakerIcon className="w-4 h-4 inline-block mr-1 text-cyan-400" />
            Liquidity: <span className="text-green-400">{data.liquidity}</span>
          </p>
        )}
      </div>
      {data.volume && (
        <div className="flex space-x-4">
          <p className="font-semibold mb-1">
            <ChartBarIcon className="w-4 h-4 inline-block mr-1 text-cyan-400" />
            Volume:
          </p>
          {data.volume.h24 !== undefined && (
            <p className="mb-1">
              24H: <span className="text-green-400">{data.volume.h24}</span>
            </p>
          )}
          {data.volume.h6 !== undefined && (
            <p className="mb-1">
              6H: <span className="text-green-400">{data.volume.h6}</span>
            </p>
          )}
          {data.volume.h1 !== undefined && (
            <p className="mb-1">
              1H: <span className="text-green-400">{data.volume.h1}</span>
            </p>
          )}
          {data.volume.m5 !== undefined && (
            <p className="mb-1">
              5M: <span className="text-green-400">{data.volume.m5}</span>
            </p>
          )}
        </div>
      )}
      {data.priceChange && (
        <div className="flex space-x-4">
          <p className="font-semibold mb-1">
            <ArrowTrendingUpIcon className="w-4 h-4 inline-block mr-1 text-cyan-400" />
            Price Chg:
          </p>
          {data.priceChange.h24 !== undefined && (
            <p className="mb-1">
              24H:
              <span className={getPriceChangeClass(data.priceChange.h24)}>
                {data.priceChange.h24}
              </span>
            </p>
          )}
          {data.priceChange.h6 !== undefined && (
            <p className="mb-1">
              6H:
              <span className={getPriceChangeClass(data.priceChange.h6)}>
                {data.priceChange.h6}
              </span>
            </p>
          )}
          {data.priceChange.h1 !== undefined && (
            <p className="mb-1">
              1H:
              <span className={getPriceChangeClass(data.priceChange.h1)}>
                {data.priceChange.h1}
              </span>
            </p>
          )}
          {data.priceChange.m5 !== undefined && (
            <p className="mb-1">
              5M:
              <span className={getPriceChangeClass(data.priceChange.m5)}>
                {data.priceChange.m5}
              </span>
            </p>
          )}
        </div>
      )}
      {data.transactions && (
        <div className="my-4">
          <div className="flex space-x-4">
            <p className="font-semibold mb-1">
              <ArrowPathIcon className="w-4 h-4 inline-block mr-1 text-cyan-400" />
              24H Txns:
            </p>
            {data.transactions.total !== undefined && (
              <p className="mb-1">
                Total:
                <span className="text-green-400">
                  {data.transactions.total}
                </span>
              </p>
            )}
            {data.transactions.buys !== undefined && (
              <p className="mb-1">
                Buys:
                <span className="text-green-400">{data.transactions.buys}</span>
              </p>
            )}
            {data.transactions.sells !== undefined && (
              <p className="mb-1">
                Sells:
                <span className="text-green-400">
                  {data.transactions.sells}
                </span>
              </p>
            )}
          </div>
        </div>
      )}
      {data.makers && (
        <div className="my-4">
          <h3 className="font-semibold">
            <UserGroupIcon className="w-4 h-4 inline-block mr-1 text-cyan-400" />
            24H Makers:
          </h3>
          <div className="flex space-x-4">
            {data.makers.total !== undefined && (
              <p className="mb-1">
                Total:
                <span className="text-green-400">{data.makers.total}</span>
              </p>
            )}
            {data.makers.buyers !== undefined && (
              <p className="mb-1">
                Buyers:
                <span className="text-green-400">{data.makers.buyers}</span>
              </p>
            )}
            {data.makers.sellers !== undefined && (
              <p className="mb-1">
                Sellers:
                <span className="text-green-400">{data.makers.sellers}</span>
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
